<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="maintenance-create-p-title">
      {{$t('processes.create.title')}}
    </h4>

    <div class="card">
      <div class="card-body">
          <b-alert v-if="error.length"
                   show
                   variant="danger"
                   qid="create-p-error"
          >
            {{ error }}
          </b-alert>
          <b-form @submit="formSubmit" qid="create-p-form">
            <div class="form-container">
              <div class="row">
                <div class="col-sm-6 pl-5 pr-3">
                  <b-form-group>
                    <label qid="create-p-name-label">
                      {{ $t('processes.create.name_label') }}
                    </label>
                    <b-form-input
                      qid="create-p-form-name"
                      type="text"
                      v-model="pForm.name"
                      :maxLength="255"
                      required
                      :placeholder="$t('processes.create.name_placeholder')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <label qid="create-p-description-label">
                      {{ $t('processes.create.description_label') }}
                    </label>
                    <b-form-textarea
                      qid="create-p-form-description"
                      v-model="pForm.description"
                      rows="6"
                      max-rows="6"
                      :placeholder="$t('processes.create.description_placeholder')"
                    ></b-form-textarea>
                  </b-form-group>
                  <b-form-group>
                    <label qid="create-p-users-label">
                      {{ $t('processes.create.owners_label') }}
                    </label>
                    <multiselect
                        v-model="selectedUsers"
                        :options="users"
                        :multiple="true"
                        :taggable="false"
                        track-by="slug"
                        :placeholder="$t('processes.create.owners_placeholder')"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        @remove="removeUserFromDropdown"
                        @search-change="loadUsers"
                        qid="create-p-users-select"
                    >
                      <template slot="tag" slot-scope="props">
                        <span></span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <Avatar :object="props.option" size="xs"></Avatar>
                        <span class="ml-2">
                          {{ props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                    <div class="form-control auto-height mt-1" v-if="selectedUsers && selectedUsers.length">
                      <div v-for="(user, index) in selectedUsers" class="mt-1 mb-1">
                        <div class="row">
                          <div class="col-sm-1">
                            <font-awesome-icon
                              icon="star"
                              :class="{'color-yellow': user.owner, 'text-light': !user.owner}"
                              class="cursor-pointer"
                              @click="setOwner(index)"
                            />
                          </div>
                          <div class="col-sm-10">
                            <Avatar :object="user" size="xs" :noOwner="true"></Avatar>
                            <span class="ml-2">{{ user.name }}</span>
                          </div>
                          <div class="col-sm-1">
                            <font-awesome-icon
                              icon="times"
                              class="cursor-pointer"
                              @click="removeUser(index)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="text-light">
                      * {{ $t('processes.create.owners_tip') }}
                    </span>
                  </b-form-group>
                </div>
                <div class="col-sm-6 pl-3 pr-5">
                  <b-form-group>
                    <label qid="create-p-pg-label">
                      {{ $t('processes.create.process_group_label') }}
                    </label>
                    <multiselect
                        v-model="processGroup"
                        :options="processGroups"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        :placeholder="$t('processes.create.process_group_placeholder')"
                        label="name"
                        @search-change="loadProcessGroups"
                        qid="create-p-form-pg"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group>
                    <label qid="create-p-purpose-label">
                      {{ $t('processes.create.purpose_label') }}
                    </label>
                    <b-form-textarea
                      qid="create-p-form-purpose"
                      v-model="pForm.purpose"
                      rows="6"
                      max-rows="6"
                      :placeholder="$t('processes.create.purpose_placeholder')"
                    ></b-form-textarea>
                  </b-form-group>
                  <b-form-group>
                    <label qid="create-do-tags-label">
                      {{ $t('processes.create.tags_label') }}
                    </label>
                    <multiselect
                        v-model="selectedTags"
                        :options="tags"
                        :multiple="true"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        :taggable="true"
                        track-by="name_translated"
                        :placeholder="$t('processes.create.tags_placeholder')"
                        label="name_translated"
                        qid="create-do-tags-select"
                        @search-change="loadTags"
                        @tag="addTag"
                    ></multiselect>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="text-right mr-4">
              <b-button class="mt-3"
                        variant="secondary"
                        @click="goToList"
                        qid="create-p-cancel-button"
              >
                {{ $t('processes.create.cancel') }}
              </b-button>
              <b-button type="submit"
                        class="mt-3 ml-3"
                        variant="success"
                        :disabled="buttonDisabled"
                        qid="create-p-submit-button"
              >
                <span v-if="buttonDisabled" class="mr-1">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </span>
                {{ $t('processes.create.submit') }}
              </b-button>
            </div>
          </b-form>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'CreateProcess',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('processes.title'),
            to: '/processing-activities'
          },
          {
            text: this.$t('processes.create.title'),
            active: true
          }
        ],
        pForm: {
          name: '',
          description: '',
          purpose: '',
          users: '',
          process_group_id: '',
          client:'',
        },
        tags: [],
        selectedTags: [],
        error: '',
        users: [],
        selectedUsers: [],
        processGroups: [],
        processGroup: [],
        newTags: [],
        buttonDisabled: false
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadUsers()
        this.loadTags()
        this.loadProcessGroups()
      }
    },
    methods: {
      goToList() {
        this.$router.push('/processing-activities')
      },
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();
        let files = document.querySelector('#file');
        let selectedOwner = false;

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        // Append list of users to FormData
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            formData.append("users[]", this.selectedUsers[i].id);
            if (this.selectedUsers[i].owner) {
              selectedOwner = true;
              formData.append("owner", this.selectedUsers[i].id);
            }
          }
        } else {
          selectedOwner = true;
        }

        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            formData.append("tags[]", this.selectedTags[i].name_translated);
          }
        }

        if (this.processGroup && this.processGroup.name) {
          formData.append("process_group_id", this.processGroup.id);
        }

        formData.append("name", this.pForm.name);
        formData.append("description", this.pForm.description);
        formData.append("purpose", this.pForm.purpose);
        formData.append("client", this.getClient.slug);

        if (selectedOwner) {
          piincHttp.post('/processes', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function(response) {
            self.$toastr('success', self.$t('processes.create.success'))
            self.buttonDisabled = false;
            self.$router.push({ name: 'processes.view', params: { slug: response.data.slug }})
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              // Print whole array of actual errors beside message
              self.error = self.error + '\n\n' + JSON.stringify(error.data.errors);
            }
          })
        } else {
          this.error = this.$t('system.owners_error_message')
          this.buttonDisabled = false;
        }
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.tags = response.data
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadProcessGroups(query) {
        let self = this;
        piincHttp.get('process-groups', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.processGroups = response.data.items
          if (self.processGroups && !self.processGroups.length && !query) {
            self.processGroups.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      }
    },
    components: {
      Avatar
    },
    created () {
      if (this.getClient && this.getClient.slug) {
        this.loadUsers()
        this.loadTags()
        this.loadProcessGroups()
      }
    }
  }
</script>
